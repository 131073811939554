:root {
  /* --brand-color: #4d8691; */
  --brand-color: #42747e;
  --secondary-color: #94b6bd;
  --max-width: 1200px;
}

body {
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.05rem;
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

.question-text {
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--brand-color);
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: -0.05rem;
}

.option-btn {
  background-color: white;
  border: 3px solid var(--brand-color);
  color: var(--brand-color);
  height: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  cursor: pointer;
  padding: 1rem 1.5rem;
  text-align: center;
  line-height: 18px;
  gap: 1rem;
  font-size: 1rem;
}

.svg-container {
  height: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-container .svg-content {
  width: 100%;
  height: 100%;
}

.svg-content path,
.svg-content polygon,
.svg-content polyline {
  stroke: var(--brand-color);
}

.selected {
  background-color: var(--brand-color);
  color: white;
}

.selected .svg-content path,
.selected .svg-content polygon,
.selected .svg-content polyline {
  stroke: white;
}

.nav-btn {
  background-color: var(--brand-color);
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 0.5em 1.5em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  border-radius: 3em;
  border: 2px solid var(--brand-color);
  transition: 0.1s ease all;
}

.cost-info select,
.dropdown-row-results select {
  font-size: 1rem;
  color: black;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: 3px solid var(--brand-color);
  border-radius: 20px;
  background-color: transparent;
  flex-wrap: wrap;
  transition: 0.1s ease all;
  font-weight: 600;
}

/* radio buttons */

.radio-wrapper,
.range-wrapper {
  display: flex;
  align-items: center;
  gap: 1em;
}

.radio-wrapper svg {
  width: 4rem;
  height: 4rem;
}

.range-wrapper svg {
  width: 4rem;
  height: 4rem;
}

.options-wrapper {
  width: 100%;
}

.option {
  display: inline;
  width: fit-content;
  position: relative;
  cursor: pointer;
}

.option > input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.option > input:checked + div,
.option > input:checked + label {
  background-color: var(--brand-color);
  color: white;
  border: 2px solid var(--brand-color);
  stroke: #fff;
  transition: 0.2s ease all;
}

.option > input + div,
.option > input + label {
  cursor: pointer;
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  stroke: var(--primaryColor);
  background-color: transparent;
  border-radius: 2em;
  padding: 0.5em 1em;
  max-width: fit-content;
  display: table-cell;
  transition: 0.2s ease all;
}

/* range sliders */

.range-label {
  display: flex;
  justify-content: space-between;
}

html input[type="range"] {
  outline: 0;
  border: 0;
  border-radius: 500px;
  width: 100%;
  /* max-width: 100%; */
  transition: box-shadow 0.2s ease-in-out;
}

html input[type="range"]:focus {
  border: 3px solid rgba(0, 123, 255, 0.5);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  html input[type="range"] {
    overflow: hidden;
    height: 2.5em;
    -webkit-appearance: none;
    background-color: var(--secondary-color);
  }

  html input[type="range"]::-webkit-slider-runnable-track {
    height: 2.5em;
    -webkit-appearance: none;
    color: var(--secondary-color);
    -webkit-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
  }

  html input[type="range"]::-webkit-slider-thumb {
    width: 2.5em;
    -webkit-appearance: none;
    height: 2.5em;
    cursor: pointer;
    background: var(--brand-color);
    box-shadow: -335px 0 0 320px var(--brand-color),
      inset 0 0 0 2em var(--brand-color);
    border-radius: 50%;
    -webkit-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
  }

  html input[type="range"]:active::-webkit-slider-thumb {
    background: #fff;
    box-shadow: -330px 0 0 320px var(--secondary-color),
      inset 0 0 0 3px var(--secondary-color);
  }
}

/* question wrapper */
.question-wrapper {
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-inline: 1rem;
  gap: 5rem;
  min-height: 60vh;
  align-items: flex-start;
}

.question-wrapper .question-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question-wrapper .options-section {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
}

.question-wrapper .non-grid-options-section {
  flex: 1;
}

.clothing-options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 4rem;
}

/* mobile view */
@media (max-width: 600px) {
  /* .question-wrapper {
    flex-direction: column;
    padding: 2rem;
    gap: 3rem;
  } */

  .question-wrapper {
    display: block;
    padding: 2rem;
  }

  .question-wrapper .question-text-section {
    flex: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .question-wrapper .options-section {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: start;
  }

  .question-wrapper .non-grid-options-section {
    margin-top: 1.5rem;
  }

  .question-text {
    margin-bottom: 0;
    font-size: 2rem;
  }

  .option-btn {
    border: 3px solid var(--brand-color);
    font-size: 15px;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    cursor: pointer;
    padding: 1rem 1rem;
    text-align: center;
    line-height: 18px;
    gap: 1rem;
  }

  .clothing-options-grid {
    margin-bottom: 0.5rem;
  }

  .range-wrapper svg {
    display: none;
  }

  .radio-wrapper svg {
    display: none;
  }
}
